<template>
  <div class="mb-5 bg-ff-color">
    <div class="main-login">
      <div class="row">
        <div class="col-lg-8 col-sm-12 m-auto">
          <h3 class="login-title">{{ $t('order_details') }}</h3>
          <div class="login-card">
            <stepper-container :steps="steps" :has-header="false">
              <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
            </stepper-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StepperContainer from "@/components/Stepper/user/StepperContainer";
import FormLevel1 from "@/components/Stepper/checker/form/FormLevel1";

export default {
  components: {StepperContainer},
  data: function () {
    return {
      steps: [
        {
          name: "form.level1",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: FormLevel1,
        }
      ],
    };
  },
}
</script>