<template>
  <div style="    ">
    <div class="container">
      <div class="row">
        <div id="left-area" class="col-md-3 col-sm-12">
          <article id="post-10841" class="post-10841 page type-page status-publish hentry">
            <h1 class="main_title">{{ resolveByLocal(post, 'title') }}</h1>
            <img :src="resolveImage(post.image)" style="width: 100%; height: 50%">
            <div class="entry-content clearfix" v-html="resolveByLocal(post, 'description')">
            </div>
          </article>
        </div>
        <div id="sidebar" class="col-md-3 col-sm-12">
          <div id="mhwidgetsocialfollow-2" class="mhc_widget widget_mhwidgetsocialfollow">
            <h4 class="widgettitle">{{ $t('follow_us') }}</h4>
            <div
              class="mh_widget_social_follow_inner mh-social-default-color mh-social-bg-color mh-social-solid-color clearfix">
              <ul class="mh-social-icons">
                <li class="mh-social-icon mh-social-twitter"
                  v-if="setting.find(item => item.key == 'twitter')?.value || false">
                  <a target="_blank" :href="setting.find(item => item.key == 'twitter')?.value" class="icon">
                    <span>Twitter</span>
                  </a>
                </li>
                <li class="mh-social-icon mh-social-facebook"
                  v-if="setting.find(item => item.key == 'facebook')?.value || false">
                  <a target="_blank" :href="setting.find(item => item.key == 'facebook')?.value" class="icon">
                    <span>Facebook</span>
                  </a>
                </li>
                <li class="mh-social-icon mh-social-instagram"
                  v-if="setting.find(item => item.key == 'instagram')?.value || false">
                  <a target="_blank" :href="setting.find(item => item.key == 'instagram')?.value" class="icon">
                    <span>Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div id="latest_posts-2" class="mhc_widget mh_list_posts">
            <h4 class="widgettitle">{{ $t('newest_articles') }}</h4>
            <ul>
              <li class="list-post" v-for="item in posts" :key="item.id">
                <router-link class="list-post-thumb" :to="{ name: 'blogItem', params: { id: item.id } }"
                  :title="resolveByLocal(item, 'title')">
                  <img width="80" height="53" :src="resolveImage(item.image)"
                    class="attachment-mhc-post-thumbnail size-mhc-post-thumbnail wp-post-image"
                    :alt="resolveByLocal(item, 'title')" loading="lazy"></router-link>
                <div class="list-post-info ">
                  <router-link :to="{ name: 'blogItem', params: { id: item.id } }" class="list-post-title">{{
                    resolveByLocal(item, 'title') }}
                  </router-link>
                  <div class="list-post-meta">
                  </div>
                </div>
                <div class="clearfix"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import apis from '@/service/apis';


export default {
  data() {
    return {
      posts: [],
      post: {},
    }
  },
  async created() {
    await this.axios.get(apis.blog).then((res) => {
      this.posts = res.data.data;
      this.post = res.data.data.find(item => item.id == this.$route.params.id);
      if (!this.post) {
        this.$router.push({
          name: 'home',
        })
      }
    }).catch(() => {
      return false;
    })
  },
  computed: {
    setting() {
      return this.$store.getters.setting !== "null" && this.$store.getters.setting != null ? this.$store.getters.setting : [];
    },
  }
}
</script>

<style lang="scss" scoped></style>