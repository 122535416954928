<template>
  <div>
    <div class="row justify-content-center align-self-center mx-2">
      <div class="col-lg-6 col-md-8">
        <div class="login-card border-gray my-4">
          <h3 class="login-title">{{ $t('reset_password') }}</h3>
          <form>
            <div class="form-group">
              <label class="form-label">{{ $t('email') }}</label>
              <input type="email" v-model="v$.form.email.$model" class="form-control"
                     id="email" tabindex="1">
            </div>
            <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
              <div class="error-msg">*{{ error.$message }}</div>
            </div>
            <div class="input-errors" v-if="erroremail">
              <div class="error-msg">*{{ erroremail }}</div>
            </div>
            <div class="login-btn my-4 text-center">
              <button class="mo-btn" style="max-width: unset" type="submit" @click="resetPassword">
                {{ $t('reset_password') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email} from '@vuelidate/validators'

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data: function () {
    return {
      form: {
        email: '',
      },
      erroremail: '',
    }
  },
  methods: {
    resetPassword: function (e) {
      e.preventDefault()
      this.v$.$validate() // checks all inputs
      if (this.v$.$error) { // if ANY fail validation
        return
      }
      this.axios.post('forgotPassword',
          {
            email: this.form.email,
          })
          .then((response) => {
            if(response.status == 200){
              this.$toast.success(this.$t('email_sent'), {
                position: 'top',
                duration: 7000
              });
              this.$router.push({
                name: 'login',
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
    }
  },
  validations() {
    return {
      form: {
        email: {required, email},
      }
    }
  }
}
</script>

<style scoped>
</style>

