<template>
  <div class="mb-5 bg-ff-color">
    <app-login-by-email>
      <div class="main-login">
        <div class="row">
          <div class="col-lg-8 col-sm-12 m-auto">
            <h3 class="login-title">{{ $t('create_new_project') }}</h3>
            <div class="login-card">
              <stepper-container :steps="steps" @confirm="completeProject">
                <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
              </stepper-container>
            </div>
          </div>
        </div>
      </div>
    </app-login-by-email>
  </div>
</template>

<script>
import StepperContainer from "@/components/Stepper/user/StepperContainer";
import UserInfo from "@/components/Stepper/user/UserInfo";
import StructureUnitData from "@/components/Stepper/user/StructureUnitData";
import CheckDetails from "@/components/Stepper/user/CheckDetails";
import OrderSummary from "@/components/Stepper/user/OrderSummary";
import PaymentStep from "@/components/Stepper/user/PaymentStep";
import apis from "@/service/apis";

export default {
  components: {StepperContainer},
  async mounted() {
    if (this.$store.getters?.project?.service_id == null) {
      this.$router.push('/services')
    }
    if (Object.keys(this.project).length === 0) {
      await this.axios.get(apis.projects + '/' + this.$route.params.id)
          .then((response) => {
            this.project = response.data?.data;
            this.$store.dispatch('updateProject', this.project)
            this.$store.dispatch('updateOrderSummary', this.project)
          });
    }
  },
  data: function () {
    return {
      steps: [
        {
          name: "customer_data",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          checked: true,
          component: UserInfo
        },
        {
          name: "structure_unit_data",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          component: StructureUnitData
        },
        {
          name: "checking_data",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          component: CheckDetails
        },
        {
          name: "payment",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          component: PaymentStep,
        },
        {
          name: "order_summary",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          confirm: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: OrderSummary,
        },
      ]
    };
  },
  methods: {
    completeProject() {
      this.$store.dispatch('completeProject').then(() => {
        this.$toast.success(this.$t('project_completed_successfully'), {
          position: 'top',
          duration: 7000
        });
        this.$store.dispatch('emptyProjects', null)
        this.$router.push('/projects');
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>