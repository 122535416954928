<template>
  <div class="mb-5 bg-ff-color">
    <app-login-by-email>
      <div class="main-login">
        <div class="row">
          <div class="col-lg-8 col-sm-12 m-auto">
            <h3 class="login-title">{{ $t('order_details') }}</h3>
            <div class="login-card" v-if="openStepper">
              <stepper-container :steps="steps" :init-step="getStepByStatus" :hasButtons="false"
                                 @confirm="completeProject">
                <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
              </stepper-container>
            </div>
          </div>
        </div>
      </div>
    </app-login-by-email>
  </div>
</template>
<script>
import StepperContainer from "@/components/Stepper/user/StepperContainer";
import OrderDetails from "@/components/Stepper/user/OrderDetails";
import UnfinishedStep from "@/components/Stepper/user/OrderSteps/UnfinishedStep";
import CheckingStep from "@/components/Stepper/user/OrderSteps/CheckingStep";
import {mapGetters,mapActions} from "vuex";

export default {
  components: {StepperContainer},
  computed: {
    ...mapGetters(['project']),
    getStepByStatus() {
      let arr = [
        {
          name: "Unfinished",
          index: 0,
        },
        {
          name: "Pending",
          index: 1,
        },
        {
          name: "Accepted",
          index: 1,
        },
        {
          name: "Chacking",
          index: 2,
        },
        {
          name: "EndCheck",
          index: 3,
        },
        {
          name: "Auditing",
          index: 3,
        },
        {
          name: "Done",
          index: 4,
        },
      ];
      const status = arr.find(item => item.name == this.project?.project?.status) || arr.find(item => item.name == this.project?.status);
      if ( status ) {
        return arr.find(item => item.name == status.name).index;
      }

      return 0
    }
  },
  async created() {
    if (this.project == undefined){
      await this.getProject(this.$route.params.id).then(() => {
        this.openStepper = true
      })
    }
    if ((Object.keys(this.project).length === 0 || this.project.id != this.$route.params.id)) {
      await this.getProject(this.$route.params.id).then(() => {
        this.openStepper = true
      })
    }
    this.openStepper = true
  },
  data: function () {
    return {
      steps: [
        {
          name: "statuses.Unfinished",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          checked: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: UnfinishedStep,
        },
        {
          name: "statuses.Pending",
          checked: true,
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: CheckingStep,
        },
        {
          name: "statuses.Checking",
          checked: true,
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: CheckingStep,
        },
        {
          name: "statuses.EndCheck",
          checked: true,
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: CheckingStep,
        },
        {
          name: "statuses.Done",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          checked: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: OrderDetails,
        },
      ],
      openStepper: false,
    };
  },
  methods: {
    ...mapActions(['getProject']),
    getStatusColorByProject(project) {
      const result = this.statuses.find((item) => item.status == project?.status);
      if (!result) {
        return "#777";
      }
      return this.$t(result.color)
    },
    completeProject() {
      this.$store.dispatch('completeProject').then(() => {
        this.$toast.success(this.$t('project_completed_successfully'), {
          position: 'top',
          duration: 7000
        });
        this.$store.dispatch('emptyProjects', null)
        this.$router.push('/projects');
      })
    },
  },
}
</script>