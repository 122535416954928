<template>
  <div>
    <div><h5>{{ $t('full_name') }}:</h5><span>{{ user.firstname + ' ' + user.lastname }}</span></div>
    <div><h5>{{ $t('phone_number') }}:</h5> <span>{{ user.phone_number }}</span></div>
    <div><h5>{{ $t('email') }}:</h5> <span>{{ user.email }}</span></div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "UserInfo",
  props: ["store"],
  computed: mapGetters([
    'user'
  ]),
  methods: {
    nextStep() {
      this.$store.dispatch('saveProject', {})
      return true
    }
  }
};
</script>

<style>
</style>
