<template>
  <div v-if="orderSummary?.project.id">
    <div class="card-info">
      <div class="justify-content-between d-flex">
        <h4>{{ resolveByLocal(orderSummary?.project.service , 'title') }}</h4>
        <h4>{{ orderSummary?.details?.total_cost }} {{ $t('currency') }}</h4>
      </div>
      <div class="justify-content-between d-flex">
        <p>{{ orderSummary?.details?.main_space }} {{ $t('unit') }} </p>
        <p>{{ orderSummary?.details?.main_space_cost }} {{ $t('currency') }}</p>
      </div>
      <div class="justify-content-between d-flex">
        <p>{{ orderSummary?.details?.secound_space }} {{ $t('unit') }}</p>
        <p>{{ orderSummary?.details?.secound_space_cost }} {{ $t('currency') }}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between d-flex">
        <p>{{ $t('building_type') }}</p>
        <p>{{ resolveByLocal(orderSummary?.project?.ownership , 'title') }} {{ orderSummary?.project?.other }}</p>
      </div>
      <div class="justify-content-between d-flex">
        <p>{{ $t('building_no') }}</p>
        <p>{{ orderSummary?.project.building_no != null ? orderSummary?.project.building_no : $t('not_exist') }}</p>
      </div>

      <div class="justify-content-between d-flex">
        <p>{{ $t('unit_no') }}</p>
        <p>{{ orderSummary?.project.unit_no != null ? orderSummary?.project.unit_no : $t('not_exist') }}</p>
      </div>
      <div class="justify-content-between d-flex">
        <p>{{ $t('street_no') }}</p>
        <p>{{ orderSummary?.project.street }}</p>
      </div>
      <div class="justify-content-between d-flex">
        <p>{{ $t('zone_no') }}</p>
        <p>{{ orderSummary?.project.title }}</p>
      </div>
      <div class="justify-content-between d-flex">
        <p>{{ $t('check_date_and_period') }}</p>
        <p>{{ orderSummary?.project.preferred_date != null ? orderSummary?.project.preferred_date : $t('open_date') }}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex align-items-center">
        <h5>{{ $t('price_offer') }}</h5>
        <div class="d-flex">
          <div class="icon-area mx-1" style="cursor:pointer;">
            <img src="../../../assets/img/download.png" @click="downloadPriceOffer">
          </div>
        </div>
      </div>
      <div class="my-2">
        <div class="justify-content-between d-flex">
          <p>{{ $t('total_cost') }}</p>
          <p class="mx-2">{{ orderSummary?.details?.total_cost }}  {{ $t('currency') }}</p>
        </div>
        <div class="justify-content-between d-flex" v-if="orderSummary?.details?.vat && orderSummary?.details?.vat > 0">
          <p>{{ $t('vat') }} ({{ orderSummary?.details?.vat }}%)</p>
          <p class="mx-2">{{ orderSummary?.details?.vat_cost }}  {{ $t('currency') }}</p>
        </div>
        <div class="justify-content-between d-flex" v-if="orderSummary?.details?.vat && orderSummary?.details?.vat > 0">
          <p>{{ $t('total') }}</p>
          <p class="mx-2" >{{ orderSummary?.details?.sub_total }}  {{ $t('currency') }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "CheckDetails",
  computed: {
    ...mapState({
      orderSummary: state => state.orderSummary
    })
  },
  methods: {
    downloadPriceOffer(){
      this.$store.dispatch('downloadPriceOffer', this.orderSummary?.project?.id).then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'offer.pdf');
        document.body.appendChild(link);
        link.click();
      })
    }
  }
};
</script>

<style>
.map {
  height: 300px;
  border-radius: 30px;
}
.card-info{
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 20px 0;
}
</style>
