<template>
  <div class="container">
    <b-row class="py-4">
      <b-col v-for="item in data" :key="item.id" col lg="4">
        <b-card :title="resolveByLocal(item, 'title')" :img-src="resolveImage(item.image)"
          :img-alt="resolveByLocal(item, 'title')" img-top class="mb-2 w-100 p-3 border-10">
          <b-card-text>
            {{ resolveByLocal(item, 'description') }}
          </b-card-text>

          <router-link to="/new-project" class="menu-item-link mt-3" @click="saveServiceId(item.id)">
            <div class="mhc-menu-item-link-content mo-btn">
              <h3 class="mhc-menu-item-link-head">{{ $t('request_service') }}</h3>
            </div>
          </router-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apis from "@/service/apis";

export default {
  name: "ServicesView",
  data() {
    return {
      data: []
    }
  },
  async created() {
    localStorage.removeItem('project');
    localStorage.removeItem('orderSummary');
    await this.axios.get(apis.services).then(res => {
      this.data = res.data.data;
    })
  },
  methods: {
    saveServiceId(id) {
      localStorage.removeItem('project');
      localStorage.removeItem('orderSummary');
      this.$store.dispatch('emptyProjects')
      this.$store.dispatch('updateProject', { service_id: id })
      // this.$store.dispatch('saveProject');
    }
  }
}
</script>

<style scoped>
.mo-btn {
  width: max-content !important;
  padding: 10px 20px !important;
}
</style>
