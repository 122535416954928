<template>
    <div class="container">

        <div class="row align-items-center" v-if="getContentTerms">
            <div class="col-12 align-self-center">
                <div class=" px-3">
                    <h2 class="mhc_slide_title pt-0 " :style='{
                            "color": getContentTerms?.titleColor ? getContentTerms?.titleColor : "#422a47",
                            "font-weight": "bold",
                            "font-size": "46px",

                        }'>{{ resolveByLocal(getContentTerms, 'title') }}
                    </h2>

                    <div class="mhc_slide_content " :style='{
                        "color": getContentTerms?.contentColor ? getContentTerms?.contentColor : "#422a47",
                    }' v-html="resolveByLocal(getContentTerms, 'text')">
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";


export default {
    data() {
        return {
        }
    },
    async created() {
        this.$store.dispatch('getContent')
    },
    methods: {

    },
    computed: {
        ...mapState(['content']),
        getContentTerms() {
            return this.content.find(item => item.name == "terms");
        },
    },
}
</script>
  
<style lang="scss" scoped></style>