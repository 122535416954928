export default {
    'ar': 'Ar',
    'en': 'En',
    "statuses":{
        "undefined": 'غير مكتمل',
        "Unfinished": 'غير مكتمل',
        "Pending": 'قيد الإنتظار',
        "Accepted": 'تم القبول',
        "Checking": 'قيد الفحص',
        "EndCheck": 'تم الانتهاء من الفحص',
        "Auditing": 'قيد التدقيق',
        "Done": 'مكتمل',
    },
    "create_new_project": "إنشاء مشروع جديد",
    "user_info": "معلومات المستخدم",
    "customer_data": "بيانات المستفيد",
    "structure_unit_data": "تفاصيل الوحدة العقارية",
    "checking_data": "تفاصيل الفحص",
    "order_summary": "ملخص الطلب",
    "click_to_see_more": "إضغط لعرض المزيد",
    "next": "التالي",
    "prev": "السابق",
    "checker_dashboard": "لوحة تحكم الفاحص",
    "dont_have_permission": "ليس لديك الصلاحيات للوصول الى هذه الصفحة",
    "name": "الاسم",
    "back": "رجوع",
    "report": "التقرير",
    "rate": "تقييم",
    "zone_no": "رقم المنطقة",
    "street_number": "رقم الشارع",
    "payment_info": "معلومات الدفع",
    "order_number": "رقم الطلب",
    "check_data": "وقت الفحص",
    "checker_name": "اسم الفاحص",
    "project_status": "حالة المشروع",
    "rate_service": "تقييم الخدمة",
    "order_details": "تفاصيل الطلب",
    "service_type": "نوع الخدمة",
    "the_message": "الرسالة",
    "check_date": "تاريخ الفحص",
    "basic_ayan": "عاين اساسي",
    "preferred_date": "الوقت المفضل",
    "period": "الفترة",
    "choose_custom_checker": "اختيار فاحص محدد",
    "create_new_order": "إنشاء طلب جديد",
    "orders": "الطلبات",
    "notes": "ملاحظات",
    "building_no": "رقم البناء",
    "unit_no": "رقم الوحدة",
    "title": "العنوان",
    "choose_answer": "إختر اجابة",
    "building_area": "مساحة السطح (متر مربع)",
    "open_date": "وقت مفتوح",
    "building_type": "نوع العقار",
    "building_details": "تفاصيل العقار",
    "other_select": "اختيار أخر",
    "street_no": "رقم الشارع",
    "send": "إرسال",
    "not_exist": "غير موجود",
    "project_completed_successfully": "تم حفظ المشروع بنجاح يرجى التحقق من البريد الالكتروني",
    "my_projects": "مشاريعي",
    "total": "المجموع",
    "total_cost": "مجموع الطلب",
    "vat": "ضريبة القيمة المضافة",
    "currency": "ريال",
    "unit": "م2",
    "check_date_and_period": "التاريخ والفترة",
    "services": "الخدمات",
    "subject": "الموضوع",
    "forget_password": "هل نسيت كلمة السر؟",
    "register_new": "تسجيل حساب جديد",
    "first_name": "الاسم الاول",
    "last_name": "الاسم الاخير",
    "password": "كلمة السر",
    "phone": "رقم الهاتف",
    "full_name": "الاسم",
    "phone_number": "رقم الهاتف",
    "email": "البريد الالكتروني",
    "site_type": "نوع العقار",
    "location": "الموقع",
    "explore_website": "تصفح الموقع",
    "about": "عن موثوق",
    "calculate_check_cost": "حساب تكلفة الفحص",
    "common_question": "الأسئلة الشائعة",
    "blog": "المدونة",
    "contact": "تواصل",
    "register_as_checker": "تسجيل فاحص",
    "login": "تسجيل الدخول",
    "request_service": "طلب خدمة",
    "contact_and_question": "للتواصل والاستفسار",
    "payment_methods": "وسائل دفع آمنة",
    "policy_1": "جميع الحقوق محفوظة لمنصة موثوق © 2019-2021 |",
    "policy_2": "اتفاقية الاستخدام",
    "policy_3": "سياسة الخصوصية",
    "policy_4": "سياسة الإلغاء والاسترداد",
    "what_we_offer": "ماذا تقدم عاين؟",
    "vision": "رؤيتنا",
    "message": "رسالتنا",
    "follow_us": "تابعنا",
    "newest_articles": "أحدث المقالات",
    "register_email": `                                              يرجى إدخال البريد الإلكتروني الخاص بك، وسيتم إرسال
                                              رسالة للتحقق من البريد الإلكتروني.`,
    "about_texts": {
        "text1": ` عاين هي منصة إلكترونية تمكنك من طلب خدمة فحص العقارات بسهولة والحصول على تقرير فحص
                                احترافي بمؤشرات سهلة القراءة بواسطة فاحصين مؤهلين. تأسست عاين في شهر سبتمبر 2017 وبدأت
                                مشوارها كمقدم لخدمة الفحص. ولكن نظرًا لحجم الطلب المتزايد قرر المؤسسون تحويلها إلى منصة
                                تقدم خدمة الفحص من خلال فاحصين مستقلين ومكاتب فحص متخصصة ذات خبرة وكفاءة، وذلك في شهر
                                أغسطس 2018.`,
        "text2": `                              منصة عاين مملوكة بالكامل لشركة منصة عاين المحدودة، وهي شركة سعودية تحمل سجل تجاري رقم
                                2051226297 ومقرها مدينة الدمام في المملكة العربية السعودية.`,
        "text3": `                              نسعى في عاين إلى تبسيط عملية فحص العقارات لمساعدة المشتري على اتخاذ قرار مبني على معلومة
                                واضحة قبل الشراء.&nbsp;ولتحقيق ذلك قمنا بتصميم مصفوفة معايير فحص متخصصة ضمن نماذج مستقاة
                                من أفضل الممارسات العالمية في هذا المجال، ثم دمجنا قوة المعرفة بسهولة التقنية لتنتج خدمة
                                سهلة الاستخدام وذات كفاءة عالية.`,
        "text4": `أن نجعل جميع التعاملات العقارية شفافة وموثوقة.`,
        "text5": `                              أن نساعد عملاءنا على اتخاذ قرارات عقارية مبنية على بينة من خلال تمكين المحترفين من جمع،
                                ومعالجة، وتصوير البيانات بواسطة تقنيات مبتكرة.`
    }

}