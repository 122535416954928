<template>
  <div class="row justify-content-center align-self-center py-4">
    <div class="row">
      <div class="col-lg-7 col-md-8 mx-auto">
        <div class="row px-3">
          <div class="col-sm-4"><h3>{{ $t('orders') }}</h3></div>
          <div class="col-sm-8 d-flex justify-content-end align-items-center">
            <router-link to="/services" class="mo-btn"> + {{ $t('create_new_order') }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 col-md-8 mx-auto">
        <div class="row">
          <div class="col-md-12 my-2 mx-2" v-for="item in projects" v-bind:key="item">
            <div class="w-100 project-card position-relative row" style="cursor: pointer" :style="{'--afterColor': getStatusColorByProject(item)}"
                 @click="moveToProjectDetails(item)">
              <div class="col">
                <div style="    margin: 10px;">
                  <h5>{{ $t('basic_ayan') }}</h5>
                </div>
              </div>
              <div class="col d-flex justify-content-end align-items-center">
                <div class="d-flex justify-content-end align-items-center">
                  <div class="project-status"
                       :style="{backgroundColor: getStatusColorByProject(item)}">
                    {{ getStatusByProject(item) }}, {{$t('click_to_see_more')}}
                  </div>
                </div>
              </div>
              <div style="    margin: 10px;">
                <h5>{{ $t('service_type') }}: {{item.service.title_ar}}</h5>
              </div>
              <div style="    margin: 10px;">
                <AddressComponent :item="item"/>
              </div>
            </div>
          </div>
          <h5 class="mx-3" v-if="!projects.length">{{$t('no_orders')}}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressComponent from "@/views/user/AddressComponent";

export default {
  components: {AddressComponent},
  mounted() {
    this.axios.get('projects')
        .then((response) => {
          this.projects = response.data.data
        })
        .catch((err) => {
          console.log(err)
        })
  },
  data: function () {
    return {
      projects: [],
      statuses: [
        {
          status: "Unfinished",
          color: "#777",
        },
        {
          status: "Pending",
          color: "#bb7110",
        },
        {
          status: "Accepted",
          color: "#4a813b",
        },
        {
          status: "Chacking",
          color: "#bb7110",
        },
        {
          status: "EndCheck",
          color: "#bb7110",
        },
        {
          status: "Auditing",
          color: "#bb7110",
        },
        {
          status: "Done",
          color: "#4a813b",
        },
      ]
    }
  },
  methods: {
    getStatusByProject(project) {
      const result = this.statuses.find((item) => item.status == project?.status);
      if (!result) {
        return this.$t('statuses.Unfinished');
      }
      return this.$t(`statuses.${result.status}`)
    },
    getStatusColorByProject(project) {
      const result = this.statuses.find((item) => item.status == project?.status);
      if (!result) {
        return "#777";
      }
      return this.$t(result.color)
    },
    moveToProjectDetails(project) {
      this.$store.dispatch('updateProject', project)
      let page = 'projectDetails';
      if (project.status == 'Unfinished'){
        page = 'updateProject'
      }
      this.$router.push({
        name: page,
        params: {id: project.id}
      })
    }
  }
}
</script>

<style scoped>
.project-card {
  background-color: #fff;
  width: 100%;
  height: max-content;
  border-radius: 20px;
}

.project-card::after {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  content: "";
  position: absolute;
  height: 100%;
  width: 13px;
  background-color: var(--afterColor);
  right: -1px;
  z-index: 0;
}

.project-status {
  width: max-content;
  height: max-content;
  padding: 10px;
  background-color: #6B1D45;
  color: white;
  border-radius: 16px;
}
</style>