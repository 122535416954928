<template>
  <div>
    <div class="container">
      <div id="content-area" class="clearfix d-inline-flex">
        <div id="left-area">
          <article id="post-10841" class="post-10841 page type-page status-publish hentry">
            <h1 class="main_title">{{ $t('about') }}</h1>
            <div class="entry-content clearfix"
              v-html="resolveByLocal(content?.find(item => item.name == 'About Mawthuq'), 'text')">
            </div>
          </article>

        </div>
        <div id="sidebar" class="">
          <div id="mhwidgetsocialfollow-2" class="mhc_widget widget_mhwidgetsocialfollow">
            <h4 class="widgettitle">{{ $t('follow_us') }}</h4>
            <div
              class="mh_widget_social_follow_inner mh-social-default-color mh-social-bg-color mh-social-solid-color clearfix">
              <ul class="mh-social-icons">
                <li class="mh-social-icon mh-social-twitter"
                  v-if="setting.find(item => item.key == 'twitter')?.value || false">
                  <a target="_blank" :href="setting.find(item => item.key == 'twitter')?.value" class="icon">
                    <span>Twitter</span>
                  </a>
                </li>
                <li class="mh-social-icon mh-social-facebook"
                  v-if="setting.find(item => item.key == 'facebook')?.value || false">
                  <a target="_blank" :href="setting.find(item => item.key == 'facebook')?.value" class="icon">
                    <span>Facebook</span>
                  </a>
                </li>
                <li class="mh-social-icon mh-social-instagram"
                  v-if="setting.find(item => item.key == 'instagram')?.value || false">
                  <a target="_blank" :href="setting.find(item => item.key == 'instagram')?.value" class="icon">
                    <span>Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  created() {
    this.$store.dispatch('getContent')
  },

  computed: {
    ...mapState(['content']),
    setting() {
      return this.$store.getters.setting !== "null" && this.$store.getters.setting != null ? this.$store.getters.setting : [];
    },
  }
}
</script>

<style lang="scss" scoped></style>
