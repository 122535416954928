<template>
  <div class="container">
    <div class="row justify-content-center align-self-center mx-2">
      <div class="col-lg-6 col-md-8">
        <div class="login-card border-gray my-4">
          <h3 class="login-title">{{ $t('login') }}</h3>
          <form>
            <div class="form-group mb-3">
              <label class="form-label">{{ $t('email') }}</label>
              <input type="email" v-model="v$.form.email.$model" class="form-control" id="email" tabindex="1">
            </div>
            <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
              <div class="error-msg">*{{ error.$message }}</div>
            </div>
            <div class="input-errors" v-if="erroremail">
              <div class="error-msg">*{{ erroremail }}</div>
            </div>
            <div class="form-group">
              <label class="form-label password">
                {{ $t('password') }}
                <!-- <a href="/password-reset/" tabindex="3" class="login-action">
                    {{ $t('forget_password') }}
                  </a> -->
                <router-link to="/password-reset" tabindex="3" class="login-action mx-2">
                  {{ $t('forget_password') }}
                </router-link>
              </label>
              <input type="password" v-model="v$.form.password.$model" class="form-control" name="password" id="password"
                tabindex="2">
            </div>
            <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
              <div class="error-msg">*{{ error.$message }}</div>
            </div>
            <div class="input-errors" v-if="errorpassword">
              <div class="error-msg">*{{ errorpassword }}</div>
            </div>
            <div class="login-btn my-4 text-center">
              <button class="mo-btn" style="max-width: unset" type="submit" @click="login">
                {{ $t('login') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  data: function () {
    return {
      form: {
        email: '',
        password: ''
      },
      erroremail: '',
      errorpassword: ''
    }
  },
  methods: {
    login: function (e) {
      e.preventDefault()
      this.v$.$validate() // checks all inputs
      if (this.v$.$error) { // if ANY fail validation
        return
      }
      this.axios.post('auth/login',
        {
          email: this.form.email,
          password: this.form.password,
        })
        .then((response) => {
          if (!response.data.errors) {
            localStorage.setItem('access_token', response.data.data.token)
            this.$store.dispatch('user', JSON.stringify(response.data.data.user)).then(() => {
              window.location.replace('/')
            })
          } else {
            if (response.data.data.errors) {
              response.data.data.errors.invalid_fields.forEach((item) => {
                if (item.field === 'password') {
                  this.errorpassword = item.message
                } else if (item.field === 'email') {
                  this.erroremail = item.message
                }
              })
            } else {
              this.erroremail = response.data.data.message
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: { required, min: minLength(8) }
      }
    }
  }
}
</script>

<style scoped></style>

