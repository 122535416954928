export default {
    login: 'login',
    register: 'register',
    newProject: 'projects',
    auth: 'auth/me',
    setting: 'setting',
    ownerships: 'ownerships',
    services: 'services',
    periods: 'periods',
    projects: 'projects',
    faqs: 'faqs',
    pricing: 'pricing',
    blog: 'blog',
    downloadFile: 'project-pdf',
    addProjectDocs: 'DocumentController/store',
    content: 'content',
    documentType: 'documenttype',
    contact: 'contact',
    getNotes: 'RecentNote',
    saveNotes: 'RecentNote/store',
    Infrastructure: 'Infrastructure',
    Feature: 'Feature',
    completeProject: 'project/complete',
    checkerProjects: 'project/accepted',
    ownershipProperty: 'ownershipproperty',
    ownershipPropertyStore: 'ownershipproperty/store',
    ownershipPropertyFormStore: 'ownershippropertyform/store',
    deleteImageFromServer: 'ownershippropertyform/deleteImg',
    ownershipPropertyFormClose: 'ownershippropertyform/close',
    addImage: 'project/addImage',
    addDetails: 'project/addDetails',
    addInfrastructure: 'Infrastructure/store',
    addFeature: 'Feature/store',
}
