import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/site/HomeView.vue'
import LoginView from '../views/site/LoginView.vue'
import FAQView from '../views/site/FAQView.vue'
import PhoneRegiseterView from '../views/site/PhoneRegisterView.vue'
import EmailRegisterView from '../views/site/EmailRegisterView.vue'
import ContactView from '../views/site/ContactView.vue'
import AboutView from '../views/site/AboutView.vue'
import CheckerRegister from '../views/checker/CheckerRegisterView.vue'
import CreateProject from '../views/user/CreateProjectView.vue'
import BlogItem from '../views/site/BlogItem.vue'
import BlogsPage from '../views/site/BlogsPage.vue'
import PrivacyPolicy from '../views/site/PrivacyPolicy.vue'
import TermsConditions from '../views/site/TermsConditions.vue'
import ReturnsPolicy from '../views/site/ReturnsPolicy.vue'

import CheckerDashboard from '../views/checker/CheckerDashboardView.vue'
import Projects from '../views/user/ProjectsView.vue'
import CreateProjectView from "@/views/user/CreateProjectView";
import store from '@/store';
import ServicesView from "@/views/user/ServicesView";
import ProjectDetails from "@/views/user/ProjectDetails";
import ProjectPreview from "@/views/user/ProjectPreview";
import UnAuthComponent from "@/views/site/UnAuthComponent";
import checkerProjectDetails from "@/views/checker/CheckerProjectDetails";
import CheckerProjectFrom from "@/views/checker/CheckerProjectFrom";
import UpdateProjectView from "@/views/user/UpdateProjectView";
import MyProfile from "@/views/site/MyProfile";
import ResetPasswordView from "@/views/site/ResetPasswordView";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/password-reset',
        name: 'resetPassword',
        component: ResetPasswordView
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQView
    },
    {
        path: '/regph',
        name: 'regph',
        component: PhoneRegiseterView
    },
    {
        path: '/register',
        name: 'register',
        component: EmailRegisterView
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView
    },
    {
        path: '/services',
        name: 'services',
        component: ServicesView,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/register-as-checker',
        name: 'register-as-checker',
        component: CheckerRegister
    },
    {
        path: '/new-project',
        name: 'new-project',
        component: CreateProject,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/update-project/:id',
        name: 'updateProject',
        component: UpdateProjectView,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/checker-dashboard',
        name: 'checkerDashboard',
        component: CheckerDashboard,
        meta: {requiresAuth: true, onlyCheckers: true}
    },

    {
        path: '/checker-project-details/:id',
        name: 'checkerProjectDetails',
        component: checkerProjectDetails,
        meta: {requiresAuth: true, onlyCheckers: true}
    },

    {
        path: '/checker-project-form/:id',
        name: 'checkerProjectFrom',
        component: CheckerProjectFrom,
        meta: {requiresAuth: true, onlyCheckers: true}
    },
    {
        path: '/projects',
        name: 'projects',
        component: Projects,
        meta: {requiresAuth: true, onlyUsers: true}

    },
    {
        path: '/un-auth',
        name: 'unAuthorize',
        component: UnAuthComponent
    },
    {
        path: '/new-project',
        name: 'newProject',
        component: CreateProjectView,
        meta: {requiresAuth: true, onlyUsers: true}
    },

    {
        path: '/project-details/:id',
        name: 'projectDetails',
        component: ProjectDetails,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/project-preview/:id',
        name: 'projectPreview',
        component: ProjectPreview,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/blog/:id',
        name: 'blogItem',
        component: BlogItem,
    },
    {
        path: '/blogs',
        name: 'blogsPage',
        component: BlogsPage,
    },
    {
        path: '/privacy-policy',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms-conditions',
        name: 'termsConditions',
        component: TermsConditions,
    },
    {
        path: '/returns-policy',
        name: 'returnsPolicy',
        component: ReturnsPolicy,
    },
    {
        path: '/my-profile',
        name: 'myProfile',
        component: MyProfile,
        meta: {requiresAuth: true}

    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass:'activeLink'
})
router.beforeEach((to, from, next) => {
    if (to.meta?.requiresAuth && store.getters.user == null) {
        next({name: 'login'})
    } else {
        if (store.getters.isCheckerUser && to.meta?.onlyUsers) {
            next({name: 'unAuthorize'})
        }
        if (!store.getters.isCheckerUser && to.meta?.onlyCheckers) {
            next({name: 'unAuthorize'})
        }

        next();
    }

})

export default router
