<template>
  <div class="mb-5 bg-ff-color">
    <app-login-by-email>
      <div class="main-login">
        <div class="row">
          <div class="col-lg-8 col-sm-12 m-auto">
            <h3 class="login-title">{{ $t('update_project') }}</h3>
            <div class="login-card">
              <stepper-container :confirmFromUpdate="confirmFromUpdate" v-if="this.project?.id" :steps="steps" :init-step="getInitStep" @confirm="redirectToProjects">
                <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
              </stepper-container>
            </div>
          </div>
        </div>
      </div>
    </app-login-by-email>
  </div>
</template>

<script>
import StepperContainer from "@/components/Stepper/user/StepperContainer";
import UserInfo from "@/components/Stepper/user/UserInfo";
import StructureUnitData from "@/components/Stepper/user/StructureUnitData";
import CheckDetails from "@/components/Stepper/user/CheckDetails";
import OrderSummary from "@/components/Stepper/user/OrderSummary";
import PaymentStep from "@/components/Stepper/user/PaymentStep";
import apis from "@/service/apis";

export default {
  components: {StepperContainer},
  async beforeMount() {
    if (!this.$route.params.id){
      this.$router.push({
        name: 'projects',
      })
    }
    if (!this.project?.id) {
      await this.axios.get(apis.projects + '/' + this.$route.params.id)
          .then((response) => {
            this.project = response.data?.data?.project;
            this.$store.dispatch('updateProject', this.project)
            this.$store.dispatch('updateOrderSummary',  response.data?.data)
          });
    }
  },
  data(){
    return {
      steps: [
        {
          name: "customer_data",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          checked: true,
          component: UserInfo
        },
        {
          name: "structure_unit_data",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          component: StructureUnitData
        },
        {
          name: "checking_data",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          component: CheckDetails
        },
        {
          name: "payment",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          component: PaymentStep,
        },
        {
          name: "order_summary",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: false,
          isClickable: true,
          checked: true,
          confirmFromUpdate: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: OrderSummary,
        },
      ],
      project: {},
    };
  },
  computed: {
    getInitStep() {
      if (this.project?.ownership_id == null
          || this.project?.building_no == null
          || this.project?.service_id == null
          || this.project?.space == null
          || this.project?.street == null
          || this.project?.unit_no == null
          || this.project?.user_id == null
          || this.project?.latitude == null
          || this.project?.longitude == null
      ) {
        return 1;
      }
      if (this.project?.preferred_date == null) {
        return 2;
      }
      if (this.project?.payment_method == null) {
        return 3;
      }
      if (this.project?.status == 'Unfinished') {
        return 3;
      }
      if (
          (this.project?.ownership_id != null
          && this.project?.building_no != null
          && this.project?.service_id != null
          && this.project?.space != null
          && this.project?.street != null
          && this.project?.unit_no != null
          && this.project?.user_id != null
          && this.project?.latitude != null
          && this.project?.longitude != null
          && this.project?.payment_method != null)
          && this.project?.status != 'Unfinished'
    ) {
        return 4;
      }

      return 0;
    }
  },
  methods:{
    redirectToProjects(){
      this.$store.dispatch('completeProject').then(() => {
        this.$toast.success(this.$t('project_completed_successfully'), {
          position: 'top',
          duration: 7000
        });
        this.$store.dispatch('emptyProjects', null)
        this.$router.push('/projects');
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>