<template>
  <div class="row justify-content-center align-self-center mx-2">
    <div class="col-lg-6 col-md-8">
      <div class="login-card border-gray my-4">
        <h3 class="login-title">{{ $t('my_profile') }}</h3>
        <div><h5>{{ $t('full_name') }}:</h5><span>{{ user.firstname + ' ' + user.lastname }}</span></div>
        <div><h5>{{ $t('phone_number') }}:</h5> <span>{{ user.phone_number }}</span></div>
        <div><h5>{{ $t('email') }}:</h5> <span>{{ user.email }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MyProfile",
  computed: mapGetters([
    'user'
  ]),
}
</script>

<style scoped>

</style>
