<template>
  <div>
    <div class="card-info">
      <div class="text-center">
        <h5>{{ $t('project_status') }}</h5>
        <br>
        <div><h2>{{ $t('statuses.' + project?.status) }}</h2></div>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('order_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('order_number') }}</p>
        <p>{{ project?.id }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('service_type') }}</p>
        <p>{{ project?.service?.title_ar }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('check_data') }}</p>
        <p>{{ project?.actual_date }}</p>

      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('building_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_type') }}</p>
        <p>{{ project?.ownership?.title_ar != null ? project?.ownership?.title_ar : project?.other }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_area') }}</p>
        <p>{{ project?.space }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_no') }}</p>
        <p>{{ project?.building_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('unit_no') }}</p>
        <p>{{ project?.unit_no }}</p>
      </div>

      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('street_number') }}</p>
        <p>{{ project?.unit_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('location') }}</p>
        <p>{{ project?.title }}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('payment_info') }}</p>
        <p>{{$t('cash')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "UnfinishedStep",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      orderSummary: state => state.orderSummary,
      project: state => state.project,
    })
  },
};
</script>

<style>
.map {
  height: 300px;
  border-radius: 30px;
}

.card-info {
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 20px 0;
}

.icon-area {
  width: 44px;
  height: 42px;
  background-color: #c7c6c6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

}

.icon-area img {
  width: 20px;
  height: 20px;
}

button {
  max-width: 75px;
  padding: 14px;
  border: 0;
}
</style>
