<template>
  <div id="app">
    <div id="page-container">
      <whatsapp-component></whatsapp-component>
      <NavBar></NavBar>
      <div>
        <div id="main-content" class="not-trans">
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component"/>
            </transition>
          </router-view>
        </div>
        <FooterComponent></FooterComponent>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NavBar from "@/components/Shared/NavBar";
import FooterComponent from "@/components/Shared/FooterComponent";
import apis from "@/service/apis";
import WhatsappComponent from "@/components/Shared/WhatsappComponent";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {
    WhatsappComponent,
    NavBar,
    FooterComponent
  },
  async created() {
    await axios.get(apis.auth, {data: {withoutMessage: true}})
        .then((response) => {
          this.$store.dispatch('user', response.data)
        })   ;
    await axios.get(apis.setting, {data: {withoutMessage: true}})
        .then((response) => {
          this.$store.dispatch('setting', response.data.data)
        })
  },
  computed: {
    ...mapGetters(['user']),
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>
