<template>
  <div class="mb-5 bg-ff-color">
    <app-login-by-email>
      <div class="main-login ">
        <div class="row">
          <div class="col-lg-8 col-sm-12 m-auto">
            <div class="login-card ">
            <h3 class="login-title">{{ $t('order_details') }}</h3>
              <stepper-container :steps="steps" :init-step="3" :hasButtons="false" :has-header="false">
                <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
              </stepper-container>
            </div>
          </div>
        </div>
      </div>
    </app-login-by-email>
  </div>
</template>
<script>
import StepperContainer from "@/components/Stepper/user/StepperContainer";
import OrderDetails from "@/components/Stepper/checker/OrderDetails";

export default {
  components: {StepperContainer},
  data: function () {
    return {
      steps: [
        {
          name: "statuses.Unfinished",
          isClickable: false,
          checked: true,
        },
        {
          name: "statuses.Pending",
          isClickable: false,
          checked: true,
        },
        {
          name: "statuses.Checking",
          isClickable: false,
          checked: true,
        },
        {
          name: "statuses.Done",
          desc: "",
          icon: "fas fa-user",
          disabled: false,
          active: true,
          isClickable: true,
          checked: true,
          hasCustomStyle: true,
          customDesign: {
            'bg-ff-color': true,
            'padding-0': true,
          },
          component: OrderDetails,
        },
      ],
    };
  },
}
</script>