<template>
    <div>
        <app-login-by-email _nghost-jnp-c192="">
            <div _ngcontent-jnp-c192="" class="main-login">
                <div _ngcontent-jnp-c192="" class="row justify-content-md-center">
                    <div _ngcontent-jnp-c192="" class="col-lg-6 col-md-12 col-12 max-width">
                        <h3 _ngcontent-jnp-c192="" class="login-title">تسجيل الدخول</h3>
                        <!---->
                        <main _ngcontent-jnp-c192="" class="login-layout">
                            <div _ngcontent-jnp-c192="" class="row no-gutters">
                                <div _ngcontent-jnp-c192="" class="col-12 m-auto">
                                    <div _ngcontent-jnp-c192="" class="login-card">
                                        <div _ngcontent-jnp-c192="" class="instructions-box">
                                            <p _ngcontent-jnp-c192="">يرجى إدخال رقم الجوال الخاص بك، وسيتم إرسال رسالة
                                                نصية للتحقق من الرقم المدخل.</p>
                                        </div>
                                        <form _ngcontent-jnp-c192="" novalidate="" id="login-form" ngnativevalidate=""
                                            class="needs-validation ng-untouched ng-pristine ng-invalid">
                                            <div _ngcontent-jnp-c192="" class="form-group form-group2"><input _ngcontent-jnp-c192=""
                                                    value="" formcontrolname="phone_number" name="phone_number"
                                                    type="tel" id="phoneNumber" maxlength="11" appphonenumber=""
                                                    placeholder="5X XXX XXXX" required=""
                                                    class="form-control ng-untouched ng-pristine ng-invalid"><span
                                                    _ngcontent-jnp-c192="" class="country-code">966+</span>
                                                <!---->
                                            </div>
                                            <server-error-msg _ngcontent-jnp-c192="" _nghost-jnp-c188="">
                                                <!---->
                                            </server-error-msg>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <div _ngcontent-jnp-c192="" class="login-btn">
                            <router-link to="login" _ngcontent-jnp-c192="" type="button"
                                class="btn btn-secondary back-button"> رجوع </router-link>
                            <button _ngcontent-jnp-c192="" type="submit" class="btn btn-primary"> تسجيل الدخول </button>
                        </div>
                        <p _ngcontent-jnp-c192="" class="confirm-msg"> بالنقر على زر "تسجيل الدخول" أنت توافق على <a
                                _ngcontent-jnp-c192="" href="#" rel="noopener" target="_blank">سياسية الخصوصية</a> .
                        </p>
                    </div>
                </div>
            </div>
        </app-login-by-email>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>