<template>
  <div>
    <div v-if="data.child?.length > 0" class="first-level-card" v-b-toggle="`accordion-${data.id}`">{{ data?.name }}  {{`- ${data?.index}`}}
    </div>
    <div @click="setDataInModal(data)" :class="{'color-btn-green': !disabled && data?.values}" class="first-level-card"
         v-else v-b-modal="`accordion-${data.id}`">{{
        data.name
      }}
    </div>
    <div v-if="data.child?.length > 0">
      <b-collapse v-for="(chi) in data.child" :key="chi.id" :id="'accordion-' + data.id">
        <b-card-body>
          <tree-view :data="chi" style="margin-right: 30px"></tree-view>
        </b-card-body>
      </b-collapse>
    </div>
    <div v-else>
      <b-modal class="properties-modal" size="lg" v-model="showModal[data.id]" :id="`accordion-${data.id}`" hide-footer hide-header
               no-close-on-backdrop>
        <h4 class="my-3">{{formatedTitle}}</h4>
        <form>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div><label style="margin-inline-end: 10px"> {{ $t('appearance') }}</label></div>
            <div style="width: 90%">
              <input
                @input="checkValue(property_data, 'appearance')"
                max="100" :disabled="disabled" min="1" type="number"
                                           v-model="property_data.appearance"
                                           class="form-control" required/>
              <div v-if="v$.property_data?.appearance?.$errors">
                <div class="input-errors" v-for="(error, index) of v$.property_data.appearance.$errors" :key="index">
                  <div class="error-msg">*{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div><label style="margin-inline-end: 20px"> {{ $t('performance') }}</label></div>
            <div style="width: 90%"><input @input="checkValue(property_data, 'performance')" max="100" :disabled="disabled" min="1" type="number"
                                           v-model="property_data.performance"
                                           class="form-control" required/>
              <div v-if="v$.property_data?.performance?.$errors">
                <div class="input-errors" v-for="(error, index) of v$.property_data.performance.$errors" :key="index">
                  <div class="error-msg">*{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div><label style="margin-inline-end: 1px"> {{ $t('notes') }}</label></div>
            <div style="width: 90%"><textarea v-model="property_data.notes" class="form-control" :disabled="disabled"
                                              required> </textarea>
              <div v-if="v$.property_data?.notes?.$errors">
                <div class="input-errors" v-for="(error, index) of v$.property_data.notes.$errors" :key="index">
                  <div class="error-msg">*{{ error.$message }}</div>
                </div>
              </div>
            </div>

          </div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div><label style="margin-inline-end: 10px"> {{ $t('images') }}</label></div>
            <div style="width: 90%"><input accept="image/*;capture=camera" :disabled="disabled"
                                           @change="appendFiles($event.target.files)"
                                           class="form-control " type="file"
                                           multiple/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 position-relative" v-for="(item,index) in images" :key="index">
              <img class="preview-image tocrop" :src="item.src"/>
              <span @click="deleteImage(item.src)" class="delete-img">{{ $t('delete') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 position-relative" v-for="(item,index) in property_data.images" :key="index">
              <img class="preview-image" :src="(resolveImageFromPublic(item))"/>
              <span @click="deleteImageFromServer(item)" class="delete-img">{{ $t('delete') }}</span>
            </div>
          </div>
          <b-button v-if="(project.status == 'Chacking' && !disabled)"
                    class="mt-5 mx-2 mo-btn" @click="sendData(data)">
            {{ $t('send') }}
          </b-button>
          <b-button class="mt-5" @click="hideModal(data.id)">{{ $t('close') }}</b-button>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, numeric} from "@vuelidate/validators";

export default {
  name: "TreeView",
  props: {
    data: {type: Object}, project: {type: Object}, disabled: {
      type: Boolean, default: () => {
        return false
      }
    }, title: {type: String}
  },
  setup() {
    return {v$: useVuelidate()}
  },
  created() {
    this.formatedTitle = this.title + ' > ' + this.data.name + this.data.index
  },
  data() {
    return {
      showModal: [],
      formatedTitle: '',
      property_data: {
        ownership_property_value_id: '',
        appearance: '',
        performance: '',
        images: '',
        notes: '',
        "images[]": [],
      },
      initValues: {},
      images: [],
    }
  },
  methods: {
    checkValue(data, prop){
      if (data[prop] > 100) {
        data[prop] = 100
      }
      if (data[prop] <= 1) {
        data[prop] = 1
      }
      if (isNaN(data[prop]) || data[prop] == '' ){
        data[prop] = 0
      }
    },

    deleteImage(src) {
      this.images = this.images.filter(item => item.src !== src);
      this.property_data['images[]'] = this.images.map((item) => item.item)
    },
    hideModal(id) {
      this.showModal[id] = false;
      let modal = document.querySelectorAll('.modal-backdrop');
      let body = document.querySelector('body');
      modal.forEach(item => item.remove());
      this.property_data = {};
      this.images = [];
      setTimeout(() => {
        body.style.overflow = 'scroll'
      }, 1000)
    },
    appendFiles(files) {
      let oldData = [];
      if (this.property_data['images[]'].length){
       oldData =  this.property_data['images[]'];
      }
      this.property_data['images[]'] = Object.values(files);

      this.property_data['images[]'].forEach(item => {
        this.images.push({
          item: item,
          src: URL.createObjectURL(item)
        });
      })
      if (oldData.length){
        this.property_data['images[]'] = oldData.concat(Object.values(files))
      }
    },
    sendData(data) {
      this.v$.$validate();
      if (this.v$.$error) {
        return
      }
      this.property_data.ownership_property_value_id = data.id;
      this.$store.dispatch('sendPropertyData', this.property_data).then((res) => {
        if (res) {
          this.$toast.success(this.$t('property_project_completed_successfully'), {
            position: 'top',
            duration: 7000
          });
        }
        this.$emit('refresh', true)
        this.hideModal(data.id);
      })
    },
    deleteImageFromServer(item) {
      this.$store.dispatch('deleteImageFromServer', {ownership_property_value_id: this.data.id, image: item}).then(() => {
        this.$emit('refresh', true)
        this.hideModal(item.id);
      })
    },
    setDataInModal(data) {
      this.showModal[data.id] = true;
      this.property_data.hasPreviousData = true;
      this.property_data.appearance = data?.values?.appearance;
      this.property_data.notes = data?.values?.notes;
      this.property_data.performance = data?.values?.performance;
      this.property_data.images = data?.values?.images;
      this.property_data.ownership_property_value_id = data.id;
      this.initValues = this.property_data;
    },
    dateAreChanged() {
      return this.initValues.appearance != this.property_data.appearance
          || this.initValues.notes != this.property_data.notes
          || this.initValues.performance != this.property_data.performance
          || this.initValues.images != this.property_data.images;
    }
  },
  validations() {
    return {
      property_data: {
        ownership_property_value_id: [required, numeric],
        appearance: [required, numeric],
        performance: [required, numeric],
        notes: [required],
      }
    }
  }
}
</script>

<style scoped>
.card-body {
  padding: 5px;
}

.preview-image {
  width: 100%;
  height: 250px;
}

.delete-img {
  position: absolute;
  bottom: 25px;
  padding: 8px;
  border-radius: 9px;
  color: white;
  background: red;
  left: 40%;
  margin: auto;
  cursor: pointer;
}

.first-level-card {
  width: 100%;
  height: 70px;
  background-color: var(--main-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}

.color-btn-green {
  background-color: green;
}
</style>
