<template>
<div class="whatsapp" @click="goToWhatsapp" :class="[local == 'ar' ? 'left' : 'right']">
  <img src="../../assets/img/whatsapp.png"/>
</div>
</template>

<script>
export default {
  name: "WhatsappComponent",
  methods: {

    goToWhatsapp(){

      window.open(`https://wa.me/` + this.setting.find(item => item.key =='whatsapp')?.value, '_blank');
    }
  },
  computed: {
    setting() {
      return this.$store.getters.setting !== "null" && this.$store.getters.setting != null ? this.$store.getters.setting : [];
    },
    local() {
      // console.log(this.$local)
      return this.$local;
    }
  }
}
</script>

<style scoped>
.whatsapp{
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: white;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.left{
  left: 20px !important;
}
.right{
  right:20px !important
}
</style>
