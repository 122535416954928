<template>
  <div>
    <app-login-by-email _nghost-jnp-c192="">
      <div class="main-login container">
        <div class="row justify-content-md-center mx-2">
          <form class="col-lg-6 col-md-12 col-12 border-gray my-4">
            <h3 class="login-title">{{ $t('register_as_checker') }}</h3>
            <p>{{ $t('register_as_checker_note') }}</p>
            <main class="login-layout">
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="login-card">
                    <div class="instructions-box mb-3">
                      <p>
                        {{ $t('register_email') }}
                      </p>
                    </div>
                    <div class="needs-validation ng-untouched ng-pristine ng-invalid row">
                      <div class="form-group col-md-6 col-lg-6 mb-3"><label class="form-label">{{ $t('first_name')
                      }}</label>
                        <input name="firstname" required v-model="v$.form.firstname.$model"
                          class="form-control ng-untouched ng-pristine ng-invalid">
                        <div class="input-errors" v-for="(error, index) of v$.form.firstname.$errors" :key="index">
                          <div class="error-msg">*{{ error.$message }}</div>
                        </div>
                      </div>
                      <div class="form-group col-md-6 col-lg-6 mb-3">
                        <label class="form-label">{{ $t('last_name') }}</label>
                        <input v-model="v$.form.lastname.$model" class="form-control ng-untouched ng-pristine ng-invalid">
                        <div class="input-errors" v-for="(error, index) of v$.form.lastname.$errors" :key="index">
                          <div class="error-msg">*{{ error.$message }}</div>
                        </div>
                      </div>
                      <div class="form-group no-margin mb-3">
                        <label class="form-label">{{ $t('email') }}</label>
                        <input v-model="v$.form.email.$model" class="form-control ng-untouched ng-pristine ng-invalid">
                      </div>
                      <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                        <div class="error-msg">*{{ error.$message }}</div>
                      </div>
                      <div class="form-group no-margin mb-3">
                        <label class="form-label">{{ $t('password') }}</label>
                        <input v-model="v$.form.password.$model" type="password"
                          class="form-control ng-untouched ng-pristine ng-invalid">
                      </div>
                      <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
                        <div class="error-msg">*{{ error.$message }}</div>
                      </div>
                      <div class="form-group no-margin mb-3">
                        <label class="form-label">{{ $t('phone') }}</label>
                        <input name="phone" v-model="v$.form.phone.$model"
                          class="form-control ng-untouched ng-pristine ng-invalid">
                      </div>
                      <div class="input-errors" v-for="(error, index) of v$.form.phone.$errors" :key="index">
                        <div class="error-msg">*{{ error.$message }}</div>
                      </div>
                    </div>
                    <p class="confirm-msg "> بالنقر على زر "تسجيل حساب جديد" أنت توافق على 

                      <router-link to="/privacy-policy" class="" target="_blank">
                        {{ $t('policy_3') }}
                      </router-link>.
                    </p>
                  </div>
                </div>
              </div>
            </main>
            <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
              <div class="error-msg">*{{ error.$message }}</div>
            </div>
            <div class="input-errors" v-if="errorpassword">
              <div class="error-msg">*{{ errorpassword }}</div>
            </div>
            <div class="d-flex justify-content-center align-items-center my-4">
              <router-link to="login" type="button" class="btn btn-secondary back-button mx-2"> {{ $t('back') }}
              </router-link>
              <button type="submit" class="mo-btn" style="max-width: unset" @click="signUp">
                {{ $t('register') }}
              </button>
            </div>

          </form>
        </div>
      </div>
    </app-login-by-email>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  data: function () {
    return {
      form: {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        phone: ''
      },
      erroremail: '',
      errorpassword: ''
    }
  },
  methods: {
    signUp: function (e) {
      e.preventDefault()
      this.v$.$validate() // checks all inputs
      if (this.v$.$error) { // if ANY fail validation
        return
      }
      this.axios.post('auth/register',
        {
          email: this.form.email,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          password: this.form.password,
          type: 'checker',
          phone_number: this.form.phone
        })
        .then((response) => {
          if (!response.data.errors) {
            localStorage.setItem('access_token', response.data.data.token)
            this.$store.dispatch('user', response.data.data.user)
            this.$router.push({ name: 'home' })
          } else {
            response.data.errors.invalid_fields.forEach((item) => {
              if (item.field === 'password') {
                this.errorpassword = item.message
              } else if (item.field === 'email') {
                this.erroremail = item.message
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  validations() {
    return {
      form: {
        firstname: { required },
        lastname: { required },
        email: { required, email },
        phone: { required },
        password: { required, min: minLength(8) }
      }
    }
  }
}
</script>

<style scoped></style>