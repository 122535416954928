<template>
    <div class="container">
        <div class=" mhc_module mhc_pct mhc_bg_layout_light  mhc_text_11">
            <h1 :style='{
                "font-weight": "bold",
                "font-size": "24px",
              }'>{{ $t('blog') }}</h1>
          </div>
        <div id="blog" class="" data-bg-color="#f3f3f3">
            <div class=" mhc_row mhc_row_7">
                <div class="mhc_column mhc_column_4_4 mhc_column_15">

                    <div class="mhc_blog_grid_wrapper  mhc_bg_layout_light">
                        <div class="mhc_blog_grid clearfix  mhc_blog_0 mhc_bg_layout_light">
                            <article id="post-8765" v-for="(item, index) in blog" :key="index" @click="moveToBlogItem(item)"
                                class="mhc_post post-meta-below post-8765 post type-post status-publish format-standard has-post-thumbnail hentry category-11 tag-inspection tag-property tag-8 tag-6 tag-9">
                                <div class="mhc_image_container">
                                    <a class="featured_image_container">
                                        <img :src="resolveImage(item.image)" :alt='resolveByLocal(item, "title")'
                                            width='400' height='250' />
                                    </a>
                                </div>
                                <div class="mhc_post_content">
                                    <div style=" text-overflow: ellipsis;" v-html="resolveByLocal(item, 'title')"></div>
                                    <hr />
                                    <button style="cursor: pointer; max-width: 200px" class="mo-btn">{{ $t('readmore')
                                    }}</button>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
  
<script>
import apis from '@/service/apis';


export default {
    data() {
        return {
            blog: [],
        }
    },
    async created() {
        this.$store.dispatch('getContent')
        await this.axios.get(apis.blog).then((res) => {
            this.blog = res.data.data;
        }).catch(() => {
            return false;
        })
    },
    methods: {
        moveToBlogItem(item) {
            this.$router.push({
                name: 'blogItem',
                params: { id: item.id }
            })
        },
    },
}
</script>
  
<style lang="scss" scoped></style>