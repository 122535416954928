<template>
  <h5>
    {{ $t('building_no') }}:
    {{item.building_no || $t('not_exist')}},
    {{ $t('unit_no') }}: {{item.unit_no || $t('not_exist')}},
    {{ $t('street_no') }}: {{item.street || $t('not_exist')}},
    {{ $t('zone_no') }}: {{item.title || $t('not_exist')}}
  </h5>
</template>
<script>
export default {
  name: 'AddressComponent',
  props: {
    item: {}
  }
}
</script>
