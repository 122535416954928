<template>
  <div>
    <div v-if="isLevel(1)">
      <div class="card-info mb-3" v-for="(item,index) in ownershipProperty" :key="index">
        <div v-if="!item.isNew">
          <div>
            <h5>{{ $t('name') }}: <b>{{ item.name }}</b></h5>
          </div>
          <div class="d-flex align-items-center">
            <label style="margin-inline-end: 5px"> {{ $t('count') }}</label>
            <input class="form-control" style="width: 100%" v-model="ownershipProperty[index].count" type="number"
                   @input="checkValue(ownershipProperty[index])"
                   :placeholder="$t('count')"/>
          </div>
        </div>
        <div v-else class="position-relative">
          <div class="d-flex justify-content-end align-items-center my-2" @click="deleteOwnershipProperty(index)">
            <div class="delete"><i class="fa fa-trash"></i></div>
          </div>
          <div class="d-flex align-items-center mb-1">
            <label style="margin-inline-end: 2px"> <b>{{ $t('name') }}</b></label>
            <input class="form-control" style="width: 100%" v-model="ownershipProperty[index].name"
                   :placeholder="$t('name')"/>
          </div>
          <div class="d-flex align-items-center">
            <label style="margin-inline-end: 5px"> {{ $t('count') }}</label>
            <input class="form-control" style="width: 100%" v-model="ownershipProperty[index].count"
                   @input="checkValue(ownershipProperty[index])" type="number"
                   :placeholder="$t('count')"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLevel(2)">
      <div class="card-info mb-3" v-for="(item,index) in ownershipProperty" :key="index">
        <h3 >{{ item.name }}  {{`- ${item.index}`}}</h3>
        <div class="card-info" v-if="!item.chiled.length">
          <h4>{{$t('no_data')}}</h4>
        </div>
        <div class="mb-3" v-for="(chiled,chiledIndex) in item.chiled" :key="chiledIndex">
          <div v-if="!chiled.isNew">
            <div>
             <h5>{{ $t('name') }}: <b>{{ chiled.name }}</b></h5>
            </div>
            <div class="d-flex align-items-center">
              <label style="margin-inline-end: 5px"> {{ $t('count') }}</label>
              <input class="form-control" style="width: 100%" @input="checkValue(ownershipProperty[index].chiled[chiledIndex])" type="number"
                     v-model="ownershipProperty[index].chiled[chiledIndex].count"
                     :placeholder="$t('count')"/>
            </div>
          </div>
          <div v-else class="position-relative">
            <div class="d-flex justify-content-end align-items-center my-2 delete-2"
                 @click="deleteOwnershipPropertyLevel2(index,chiledIndex)">
              <div class="delete"><i class="fa fa-trash"></i></div>
            </div>
            <div class="d-flex align-items-center mb-1">
              <label style="margin-inline-end: 2px"> <b>{{ $t('name') }}</b></label>
              <input class="form-control" style="width: 100%"
                     v-model="ownershipProperty[index].chiled[chiledIndex].name"
                     :placeholder="$t('name')"/>
            </div>
            <div class="d-flex align-items-center">
              <label style="margin-inline-end: 5px"> {{ $t('count') }}</label>
              <input class="form-control" style="width: 100%" type="number"
                     v-model="ownershipProperty[index].chiled[chiledIndex].count"
                     @input="checkValue(ownershipProperty[index].chiled[chiledIndex])"
                     :placeholder="$t('count')"/>
            </div>
          </div>
        </div>
        <button @click="addToLevel2(ownershipProperty[index].chiled)" class="mo-btn">{{
            $t('add_other')
          }}
        </button>
      </div>
    </div>
    <div v-if="isLevel(3)">
      <div v-for="(item,index) in ownershipProperty" :key="index">
        <h3 class="text-center">{{ item.name }} {{`- ${item.index}`}}</h3>
        <div class="card-info" v-if="!item.chiled.length">
          <h4>{{$t('no_data')}}</h4>
        </div>
        <div class="card-info mb-3" v-for="(chiled,chiledIndex) in item.chiled" :key="chiledIndex">
          <h3 class="text-center"> {{ item.name }} {{`- ${item.index}`}} {{ chiled.name }} {{`- ${chiled.index}`}}</h3>
          <div class="mb-3" v-for="(subchiled, subchiledIndex) in chiled.chiled" :key="subchiled">
            <div v-if="!subchiled.isNew">
              <div>
                <h5>{{ $t('name') }}: <b>{{ subchiled.name }}</b></h5>
              </div>
              <div class="d-flex align-items-center">
                <label style="margin-inline-end: 5px"> {{ $t('count') }}</label>
                <input class="form-control" style="width: 100%" type="number"
                       @input="checkValue(ownershipProperty[index].chiled[chiledIndex].chiled[subchiledIndex])"
                       v-model="ownershipProperty[index].chiled[chiledIndex].chiled[subchiledIndex].count"
                       :placeholder="$t('count')"/>
              </div>
            </div>
            <div v-else class="position-relative">
              <div class="d-flex justify-content-end align-items-center my-2 delete-2"
                   @click="deleteOwnershipPropertyLevel3(index, chiledIndex,subchiledIndex)">
                <div class="delete"><i class="fa fa-trash"></i></div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <label style="margin-inline-end: 2px"> <b>{{ $t('name') }}</b></label>
                <input class="form-control" style="width: 100%"
                       v-model="ownershipProperty[index].chiled[chiledIndex].chiled[subchiledIndex].name"
                       :placeholder="$t('name')"/>
              </div>
              <div class="d-flex align-items-center">
                <label style="margin-inline-end: 5px"> {{ $t('count') }}</label>
                <input class="form-control" style="width: 100%" type="number"
                       @input="checkValue(ownershipProperty[index].chiled[chiledIndex].chiled[subchiledIndex])"
                       v-model="ownershipProperty[index].chiled[chiledIndex].chiled[subchiledIndex].count"
                       :placeholder="$t('count')"/>
              </div>
            </div>
          </div>
          <button @click="addToLevel3(ownershipProperty[index].chiled[chiledIndex].chiled)" class="mo-btn">{{
              $t('add_other')
            }}
          </button>
        </div>
      </div>
    </div>
    <div class="justify-content-between d-flex align-items-center">
      <button v-if="isLevel(1)" @click="addToLevel1(ownershipProperty)" class="mo-btn">{{
          $t('add_other')
        }}
      </button>
      <button @click="confirm" class="confirm mo-btn">{{ $t('send') }}</button>
    </div>
  </div>
</template>

<script>
import apis from "@/service/apis";

export default {
  name: "FormLevel1",
  data() {
    return {
      ownershipProperty: [],
      projectId: [],
      isNextStep: false,
      response: [],
      formValid: true,
    }
  },
  async created() {
    this.projectId = this.$route.params.id
    this.response = await this.axios.get(apis.ownershipProperty + `/${this.projectId}`).then(res => res.data.data);
    this.ownershipProperty = this.response[1]?.tree.map(item => {
      if (item.count == 0 && !item?.chiled?.length) {
        item.count = 1;
      }
      if (item?.chiled?.length){
        item.chiled = item.chiled.map(child1 => {
          if (child1?.chiled?.length) {
            child1.chiled = child1.chiled.map(child2 => {
              if (child2.count == 0) {
                child2.count = 1;
              }
              return child2;
            })
          }
          if (child1.count == 0 && !child1?.chiled?.length) {
            child1.count = 1;
          }
          return child1;
        })
      }
      return item;
    });
    this.checkIfWeFinished();
  },
  methods: {
    validateTrees(tree){
      tree.forEach(item => {
        if (item.chiled) {
          this.validateTrees(item.chiled)
        }
        if (!item.name.length || item.count < 0){
          alert('please check all inputs');
          this.formValid = false;
        }
      })
    },
    async confirm() {
      this.validateTrees(this.ownershipProperty);
      if (this.formValid){
        return await this.$store.dispatch('saveProjectOwnershipProperty', {
          project_id: this.projectId,
          items: this.ownershipProperty,
        }).then(async () => {
          this.response = await this.axios.get(apis.ownershipProperty + `/${this.projectId}`).then(res => res.data.data);
          this.ownershipProperty = this.response[1].tree.map(item => {
            if (item.count == 0 && !item?.chiled?.length) {
              item.count = 1;
            }
            if (item?.chiled?.length){
              item.chiled = item.chiled.map(child1 => {
                if (child1?.chiled?.length) {
                  child1.chiled = child1.chiled.map(child2 => {
                    if (child2.count == 0) {
                      child2.count = 1;
                    }
                    return child2;
                  })
                }
                if (child1.count == 0 && !child1?.chiled?.length) {
                  child1.count = 1;
                }
                return child1;
              })
            }
            return item;
          });
          this.checkIfWeFinished();
        }).catch(err => {
          this.$toast.error(err, {
            position: 'top',
            duration: 7000
          });
        });
      }

    },
    deleteOwnershipProperty(index) {
      this.ownershipProperty = this.ownershipProperty.filter((item, i) => i !== index)
    },

    deleteOwnershipPropertyLevel2(parentIndex, childIndex) {
      this.ownershipProperty[parentIndex].chiled = this.ownershipProperty[parentIndex].chiled.filter((item, i) => i !== childIndex)
    },

    deleteOwnershipPropertyLevel3(parentIndex, subChild, subChildIndex) {
      this.ownershipProperty[parentIndex].chiled[subChild].chiled = this.ownershipProperty[parentIndex].chiled[subChild].chiled.filter((item, i) => i !== subChildIndex)
    },
    checkValue(d, v = 99){
      if (d.count > v) {
        d.count = v
      }
      if (d.count <= 0) {
        d.count = 0
      }
      if (isNaN(d.count) || d.count == '' ){
        d.count = 0
      }
    },
    addToLevel1() {
      this.ownershipProperty.push({
        ownership_property_id: null,
        isNew: true,
        name: "",
        count: 1,
      })
    },
    addToLevel2(node) {
      node.push({
        isNew: true,
        name: "",
        ownership_property_id: null,
        count: 1,
      })
    },
    addToLevel3(node) {
      node.push({
        isNew: true,
        ownership_property_id: null,
        name: "",
        count: 1,
      })
    },
    isLevel(level) {
      return this.response[0]?.level == level;
    },
    checkIfWeFinished() {
      if (!this.ownershipProperty.length) {
        this.$toast.success(this.$t('already_project_form_submitted'), {
          position: 'top',
          duration: 7000
        });

        this.$router.push({
          name: "checkerProjectDetails",
          id: this.projectId
        });
      }
    }
  },
}
</script>

<style scoped>
.add-btn {
  max-width: unset;
  width: max-content !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  background-color: #6B1D45;
  color: white;
}

.delete {
  width: 30px;
  height: 30px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.delete i {
  color: white;
}

.delete-2 {
  position: absolute;
  left: 5px;
  bottom: 42px;
}
</style>
