<template>
  <div>
    <div class="form-group form-group mt-3">
      <p>{{ $t('site_type') }}</p>
      <select
          v-model="form.ownership_id"
          @change="checkIfOtherOptionSelected($event)"
          class="form-control select-control ng-untouched ng-pristine ng-invalid">
        <option value="">{{ $t('choose_answer') }}</option>
        <option v-for="item in ownerships" :key="item.id" :value="item.id">
          {{ resolveByLocal(item, 'title') }}
        </option>
        <option value="-1"> {{ $t('other_select') }}</option>
      </select>
      <div class="input-errors" v-for="(error, index) of v$.form.ownership_id.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group form-group mt-3" v-if="otherOptionIsEnabled">
      <label class="my-2">{{ $t('other_select') }}</label>
      <input class="form-control" v-model="form.other" :placeholder="$t('other_select')">
      <div class="input-errors" v-for="(error, index) of v$.form.other.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group form-group mt-3">
      <label class="my-2">{{ $t('building_area') }}</label>
      <input class="form-control" type="number" v-model="form.space" :placeholder="$t('building_area')">
      <div class="input-errors" v-for="(error, index) of v$.form.space.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group form-group mt-3">
      <label class="my-2">{{ $t('location') }}</label>
      <GMapMap
          :center="center"
          :zoom="13"
          map-type-id="terrain"
          style="width: 100vw; height: 20rem"
      >
        <GMapMarker
            :position="center"
            :clickable="true"
            :draggable="true"
            @dragend="markerChanged($event)"
        >
        </GMapMarker>
      </GMapMap>
    </div>
    <div class="form-group form-group mt-3">
      <label class="my-2">{{ $t('zone_no') }}</label>
      <input class="form-control" @input="checkValue('title')"  type="number" v-model="form.title" :placeholder="$t('zone_no')">
      <div class="input-errors" v-for="(error, index) of v$.form.title.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group form-group mt-3">
      <label class="my-2">{{ $t('street_no') }}</label>
      <input class="form-control" @input="checkValue('street', 999)" type="number" v-model="form.street"
             :placeholder="$t('street_no')">
      <div v-if="v$.form?.street?.$errors">
        <div class="input-errors" v-for="(error, index) of v$.form.street.$errors" :key="index">
          <div class="error-msg">*{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="form-group form-group mt-3">
      <label class="my-2">{{ $t('building_no') }}</label>
      <input class="form-control" @input="checkValue('building_no')" type="number" v-model="form.building_no" :placeholder="$t('building_no')">
      <div v-if="v$.form?.building_no?.$errors">
        <div class="input-errors" v-for="(error, index) of v$.form.building_no.$errors" :key="index">
          <div class="error-msg">*{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="form-group form-group mt-3">
      <label class="my-2">{{ $t('unit_no') }}</label>
      <input class="form-control" @input="checkValue('unit_no')" type="number" v-model="form.unit_no" :placeholder="$t('unit_no')">
      <div v-if="v$.form?.unit_no?.$errors">
        <div class="input-errors" v-for="(error, index) of v$.form.unit_no.$errors" :key="index">
          <div class="error-msg">*{{ error.$message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from "@/service/apis";
import {numeric, required, minValue, maxValue} from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import {mapGetters} from "vuex";

export default {
  name: "StructureUnitData",
  props: ["store"],
  setup() {
    return {v$: useVuelidate()}
  },
  async created() {
    const interval = setInterval(() => {
      const btn = document.querySelector('.dismissButton');
      if (btn) {
        btn.click();
        clearInterval(interval);
      }
    }, 1000)
    this.ownerships = await this.axios.get(apis.ownerships)
        .then((response) => response.data.data);
    this.form.location = `${this.center.lat}, ${this.center.lng}`;
    this.form.latitude = this.center.lat;
    this.form.longitude = this.center.lng;
    if (this.project?.ownership_id && this.$route.params?.id != 'undefined') {
      this.form.ownership_id = this.project?.ownership_id;
      this.form.space = this.project?.space;
      this.form.building_no = this.project?.building_no;
      this.form.unit_no = this.project?.unit_no;
      this.form.title = this.project?.title;
      this.form.location = this.project?.location;
      this.form.other = this.project?.other;
      this.form.street = this.project?.street;
    }
  },
  computed: {
    ...mapGetters(['project']),
  },
  data() {
    return {
      form: {
        ownership_id: "",
        space: "",
        building_no: "",
        unit_no: "",
        title: "",
        location: "",
        other: "",
        street: "",
      },
      center: {lat: 25.2835518, lng: 51.5277331},
      ownerships: [],
      otherOptionIsEnabled: false,
    };
  },
  methods: {
    checkValue(d, v = 99){
      if (this.form[d] > v) {
        this.form[d] = v
      }
      if (this.form[d] < 0) {
        this.form[d] = 0
      }
      if (isNaN(this.form[d]) || this.form[d] == '' ){
        this.form[d] = 0
      }
    },
    nextStep() {
      this.v$.$validate();
      if (this.v$.$error) {
        return
      }
      if (this.otherOptionIsEnabled) {
        this.form.ownership_id = null;
      }
      this.$store.dispatch('updateProject', this.form).then(() => {
        this.$store.dispatch('saveProject')
      })

      return true
    },
    markerChanged(event) {
      this.form.latitude = event.latLng.lat();
      this.form.longitude = event.latLng.lng();
      this.form.location = `${this.form.latitude}, ${this.form.longitude}`;
    },
    checkIfOtherOptionSelected(event) {
      if (event.target.value == -1) {
        this.otherOptionIsEnabled = true;
      } else {
        this.otherOptionIsEnabled = false;
      }
    }
  },
  validations() {
    const validations = {
      form: {
        ownership_id: {required},
        space: {required, numeric},
        title: [required, minValue(10), maxValue(99)],
        // location: {required},
        other: {},
      }
    }
    if (this.otherOptionIsEnabled) {
      validations.form.other = [required];
    }
    if (this.form.street > 0) {
      validations.form.street = [minValue(100), maxValue(999)];
    }
    if (this.form.building_no > 0) {
      validations.form.building_no = [minValue(10), maxValue(99)];
    }
    if (this.form.unit_no >= 0) {
      validations.form.unit_no = [minValue(0), maxValue(9999)];
    }
    return validations;
  }
};
</script>

<style>
.map {
  height: 300px;
  border-radius: 30px;
}
</style>
