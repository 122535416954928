<script>

export default {
  name: "ImagesMixin",
  methods: {
    resolveImage: function (url) {
      return process.env.VUE_APP_BASE_BACKEND_URL + "/" + url;
    },
    resolveImageFromPublic: function (url) {
      return process.env.VUE_APP_BASE_BACKEND_PUBPLIC_URL + url;
    }
  }
}
</script>