<template>
    <div>
        <div class="mhc_section  mhc_section_0 mh_section_regular">
            <div class=" mhc_row mhc_row_0">
                <div class="mhc_column mhc_column_4_4 mhc_column_0">
                    <div class="mhc_text mhc_module mhc_pct mhc_bg_layout_light mhc_text_align_right  mhc_text_0">
                        <h1>الأسئلة الشائعة</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="mhc_column mhc_column_4_4 mhc_column_1">
                        <div class="mhc_module mhc_accordion  mhc_accordion_0" v-for="(item,index) in faqs" :key="index">
                            <div class="mhc_toggle mhc_toggle_close  mhc_accordion_item_1 mhc_show_borders mhc_module mhc_bg_layout_light">
                                <h5 class="mhc_toggle_title" v-b-toggle="`collapse-${index}`"> {{resolveByLocal(item, 'title')}}</h5>
                                <b-collapse :id="('collapse-' + index)" class="mt-2">
                                    <b-card>
                                        <p>{{resolveByLocal(item, 'description')}}</p>
                                    </b-card>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
            faqs: [],
        }
    },
    async created() {
        this.faqs  = await this.$store.dispatch('getFAQs').then(res => res);
    }
}
</script>

