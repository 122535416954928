import {createApp} from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import {createI18n} from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import './service/axios'
import BootstrapVue3 from 'bootstrap-vue-3'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import loading from './directives/loading'
import '../node_modules/nprogress/nprogress.css'


import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from './service/axios'
import VueAxios from 'vue-axios'
import ar from "@/lang/ar";
import en from "@/lang/en";
import ImagesMixin from "@/mixins/ImagesMixin";
import LocalResolver from "@/mixins/LocalResolver";
import Toaster from '@meforma/vue-toaster';
import moment from "moment";

require('bootstrap')
require('axios')

const local = localStorage.getItem('local') || 'ar';
if (local == 'ar') {
    document.querySelector('html').dir = 'rtl';
    import ('./assets/css/main.css')
} else {
    document.querySelector('html').dir = 'ltr';
    import ('./assets/css/main-ltr.css')
}
const i18n = createI18n({
    locale: store.getters.local,
    messages: {ar, en}
})

const app = createApp(App).use(i18n);
app.use(BootstrapVue3);
app.use(Toaster, {
    position: "top",
});

app.config.globalProperties.$local = store.getters.local || 'ar'



// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker);

app.directive('loading', loading);
app.mixin(ImagesMixin)
app.mixin(LocalResolver)
app.config.globalProperties.$filters = {
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('MM/DD/YYYY hh:mm')
        }
    }
}

app.use(store).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDfRqStj1PM2aUx3MsmhsBAWxVutO02sMI',
    },
}).use(router).use(VueAxios, axios).mount('#app')
