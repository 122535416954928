<template>
  <div class="bg-ff-color">

  <div class="container">
    <div class="card-info">
      <div class="text-center">
        <h5>{{ $t('project_status') }}</h5>
        <p><b>{{ $t(`statuses.${project?.project?.status}`) }}</b></p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('order_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('order_number') }}</p>
        <p>{{ project?.project?.id }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('service_type') }}</p>
        <p>{{ resolveByLocal(project?.project?.service, "title") }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('check_data') }}</p>
        <p>{{ $filters?.formatDate(project?.checkerinfo?.date)  }}</p>

      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('checker_name') }}</p>
        <p>{{ project?.checkerinfo?.name }}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('building_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_type') }}</p>
        <p>{{ project?.project?.ownership?.title_ar != null ? project?.project?.ownership?.title_ar : project?.project?.other }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_area') }}</p>
        <p>{{ project?.project?.space }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_no') }}</p>
        <p>{{ project?.project?.building_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('unit_no') }}</p>
        <p>{{ project?.project?.unit_no }}</p>
      </div>

      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('street_number') }}</p>
        <p>{{ project?.project?.unit_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('location') }}</p>
        <p>{{ project?.project?.title }}</p>
      </div>
    </div>
    <div class="text-center my-2" v-if="!project?.project?.hierarchy_done || project?.project?.hierarchy_done.length == 0">
      <router-link :to="'/checker-project-form/' + project?.project?.id" class="mo-btn" style="max-width: unset">
        {{ $t('request_new_form') }}
      </router-link>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('project_document') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex my-2" v-for="item in project?.documents" :key="item.id">
        <p>{{ item.name }}</p>
        <i class="fa fa-check fa-2x" style="color: green" v-if="item.uploaded == 'true'"></i>
        <i class="fa fa-xmark fa-2x" style="color: red" v-else></i>
      </div>
    </div>  
    <div class="card-info">
      <div class="row">
        <div class="justify-content-between align-items-center d-flex">
          <h5>{{ $t('project_form') }}</h5>
        </div>
        <div class="col-md-4 col-sm-12 mb-2" v-for="(item, key) in project?.project?.hierarchy_done" :key="key">
          <div class="first-level-card" @click="showTreeLevel1(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-info" v-show="treeLevel1IsShown">
      <div class="row">
        <div class="justify-content-between align-items-center d-flex">
          <h5>{{ level1Name }}</h5>
        </div>
        <div class="col-md-4 col-sm-12 mb-2" v-for="(item, key) in treeLevel1" :key="key">
          <div class="first-level-card" @click="showTreeLevel2(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="card-info" v-show="treeLevel2IsShown">
      <div class="row">
        <div class="col-md-4 col-sm-12 mb-2" v-for="(item, key) in treeLevel2" :key="key">
          <tree-view :disabled="true" :data="item" :project="project"></tree-view>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import apis from "@/service/apis";
import TreeView from "@/components/Shared/TreeView";

export default {
  name: "OrderDetails",
  components: {TreeView},
  data() {
    return {
      project: {},
      treeLevel1: [],
      level1Name: '',
      treeLevel1IsShown: false,
      treeLevel2: [],
      level2Name: '',
      treeLevel2IsShown: false,
      treeLevel3: [],
      level3Name: '',
      treeLevel3IsShown: false,
    };
  },
  methods: {
    showTreeLevel1(data) {
      this.level1Name = data.name;
      this.treeLevel1IsShown = true;
      this.treeLevel1 = data.child;
    },
    showTreeLevel2(data) {
      this.level2Name = data.name;
      this.treeLevel2IsShown = true;
      this.treeLevel2 = data.child;
    },
    closeForm(){
      this.$store.dispatch('closeForm', this.$route.params.id).then(() => {
        this.$router.push({
          name: 'checkerDashboard',
        })
      });
    }
  },
  async created() {
    await this.axios.get(apis.projects + '/' + this.$route.params.id)
        .then((response) => {
          this.project = response.data?.data
        })
  },
};
</script>

<style>
.map {
  height: 300px;
  border-radius: 30px;
}
.project-card{
  border: 1px solid var(--afterColor);
}
.card-info {
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 20px 0;
}

.icon-area {
  width: 44px;
  height: 42px;
  background-color: #c7c6c6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

}

.icon-area img {
  width: 20px;
  height: 20px;
}

button {
  max-width: 75px;
  padding: 14px;
  border: 0;
}

.first-level-card {
  width: 100%;
  height: 70px;
  background-color: var(--main-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}
</style>
