<template>
  <div>
    <div class="form-group form-group mt-3">
      <p>{{ $t('preferred_date') }}</p>
      <input type="date"
             class="date-input"
             v-model="form.preferred_date"
             :min="minDate"
             :disabled="open_date">
      <div class="input-errors" v-for="(error, index) of v$.form.preferred_date?.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group form-group mt-3">
      <p>{{ $t('preferred_time') }}</p>
      <div class="d-flex align-center">
        <select type="time"
                class="mx-1"
                v-model="form.preferred_time_pm"
                :disabled="open_date">
          <option value="" disabled selected>PM/AM</option>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
        <select type="time"
                class="mx-1"
                v-model="form.preferred_time_minute"
                :disabled="open_date">
          <option value="" disabled :selected="form.preferred_time_minute.length">Minutes</option>
          <option value="0">00</option>
          <option value="30">30</option>
        </select>
        <select type="time"
                class="mx-1"
                v-model="form.preferred_time"
                :disabled="open_date">
          <option value="" disabled selected>Hours</option>
          <option v-for="(item, key) in times" :value="item" :key="key">{{ item }}</option>
        </select>
      </div>
      <div class="input-errors" v-for="(error, index) of v$.form.preferred_time?.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
      <div class="input-errors" v-for="(error, index) of v$.form.preferred_time_hour?.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
      <div class="input-errors" v-for="(error, index) of v$.form.preferred_time_pm?.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
    </div>
    <div class="my-2">
      <div class="form-group mt-3 d-flex align-center">
        <input type="checkbox"
               @change="openDateClicked()"
               class="form-control checkbox mx-2">
        <p>{{ $t('open_date') }}</p>
      </div>
      <div class="form-group form-group mt-3 d-flex align-center">
        <input type="checkbox"
               @change="choose_custom_checker = !choose_custom_checker"
               class="form-control checkbox mx-2">
        <p>{{ $t('choose_custom_checker') }}</p>
      </div>
    </div>
    <div class="form-group form-group mt-3" v-if="choose_custom_checker">
      <p>{{ $t('choose_custom_checker') }}</p>
      <input type="number" v-model="form.checker_sn"
             class="form-control select-control ng-untouched ng-pristine ng-invalid">
      <div class="input-errors" v-for="(error, index) of v$.form.checker_sn?.$errors" :key="index">
        <div class="error-msg">*{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group form-group mt-3">
      <p>{{ $t('notes') }}</p>
      <textarea v-model="form.note" class="form-control select-control ng-untouched ng-pristine ng-invalid">
      </textarea>
    </div>
  </div>
</template>

<script>
import {required} from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import {mapGetters} from "vuex";

export default {
  name: "CheckDetails",
  props: ["store"],
  setup() {
    return {v$: useVuelidate()}
  },
  async created() {
    if (this.project?.ownership_id || this.$route.params?.id != 'undefined'){
      const date = this.project?.preferred_date.split(' ');
      this.form.preferred_date = date[0];
      this.form.preferred_time = date[1].split(':')[0] < 10 ? date[1].split(':')[0].slice(1,2) : date[1].split(':')[0];
      this.form.preferred_time_pm = date[1].split(':')[0] < 10 ? 'AM' : 'PM';
      this.form.preferred_time_minute = date[1].split(':')[1]
    }
  },

  data() {
    return {
      form: {
        preferred_date: "",
        preferred_time: "",
        preferred_time_minute: "",
        preferred_time_pm: "",
        checker_sn: null,
        note: null,
      },
      open_date: false,
      minDate: new Date().toISOString().split('T')[0],
      choose_custom_checker: false,
    };
  },
  computed: {
    times() {
      const times = [];
      for (var i = 1; i <= 12; i++) {
        times.push(i);
      }
      return times
    },
    ...mapGetters(['project']),
  },
  methods: {
    nextStep() {
      this.v$.$validate();
      if (this.v$.$error) {
        return
      }
      if (this.form.preferred_date && this.form.preferred_time_minute && this.form.preferred_time_pm){
        if (this.form.preferred_time_pm == 'AM' && this.form.preferred_time < 10){
          this.form.preferred_time = '0' + this.form.preferred_time;
        }
        this.form.preferred_date = `${this.form.preferred_date} ${this.form.preferred_time}:${this.form.preferred_time_minute}`
      }
      this.$store.dispatch('updateProject', this.form).then(() => {
        this.$store.dispatch('saveProject')
      })
      if (this.$store.getters.orderSummary != null) {
        return true;
      }

      return true;
    },
    openDateClicked() {
      this.form.preferred_date = '';
      this.form.preferred_time = '';
      this.form.preferred_time_minute = '';
      this.form.preferred_time_pm = '';
      this.open_date = !this.open_date;
    }
  },
  validations() {
    const validations = {
      form: {}
    };
    if (!this.open_date) {
      validations.form.preferred_date = {
        required, minValue(val) {
          return new Date(val) > new Date();
        }
      };
      validations.form.preferred_time = {required}
      validations.form.preferred_time_minute = {required}
      validations.form.preferred_time_pm = {required}
    } else {
      validations.form.preferred_date = []
    }
    if (this.choose_custom_checker) {
      validations.form.checker_sn = [required]
    } else {
      validations.form.checker_sn = []
    }

    return validations
  }
};
</script>

<style>
.map {
  height: 300px;
  border-radius: 30px;
}

.checkbox {
  width: 25px !important;
  height: 25px !important;
}
.date-input{
  padding: 5px;
  border-radius: 5px;
}
</style>
