<template>
  <div>
    <div class="card-info">
      <div class="text-center">
        <h5>{{ $t('project_status') }}</h5>
        <br>
        <div><h2>{{ $t('statuses.' + project?.project?.status) }}</h2></div>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex align-items-center">
        <h5>{{ $t('report') }}</h5>
        <div class="d-flex">
          <div class="icon-area mx-1" @click="navigateToProjectPreview" style="cursor:pointer;">
            <img src="../../../assets/img/eye.png">
          </div>
          <div class="icon-area mx-1" style="cursor:pointer;">
            <img src="../../../assets/img/download.png" @click="downloadFile">
          </div>
        </div>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('order_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('order_number') }}</p>
        <p>{{ project?.project?.id }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('service_type') }}</p>
        <p>{{ project?.project?.service?.title_ar }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('check_data') }}</p>
        <p>{{ $filters?.formatDate(project?.checkerinfo?.date) }}</p>

      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('checker_name') }}</p>
        <p>{{ project.checkerinfo?.name }}</p>

      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('building_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_type') }}</p>
        <p>{{
            project?.project?.ownership?.title_ar != null ? project?.project?.ownership?.title_ar : project?.project?.other
          }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_area') }}</p>
        <p>{{ project?.project?.space }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_no') }}</p>
        <p>{{ project?.project?.building_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('unit_no') }}</p>
        <p>{{ project?.project?.unit_no }}</p>
      </div>

      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('street_number') }}</p>
        <p>{{ project?.project?.unit_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('zone_no') }}</p>
        <p>{{ project?.project?.title }}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('payment_info') }}</p>
        <p>{{$t('cash')}}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex align-items-center">
        <h5>{{ $t('price_offer') }}</h5>
        <div class="d-flex">
          <div class="icon-area mx-1" style="cursor:pointer;">
            <img src="../../../assets/img/download.png" @click="downloadPriceOffer">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import apis from "@/service/apis";
import {mapState} from "vuex";

export default {
  name: "OrderDetails",
  data() {
    return {
      project: {}
    };
  },
  async created() {
    if (!this.project.project) {
      await this.axios.get(apis.projects + '/' + this.$route.params.id)
          .then((response) => {
            this.project = response.data?.data;
          });
    }
  },
  computed: {
    ...mapState({
      project: state => state.project,
    }),
  },
  methods: {
      downloadPriceOffer(){
        this.$store.dispatch('downloadPriceOffer', this.project?.id).then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'offer.pdf');
          document.body.appendChild(link);
          link.click();
        })
      },
    navigateToProjectPreview() {
      this.$store.dispatch('updateProject', this.project)
      this.$router.push({
        name: 'projectPreview',
        params: {id: this.project?.project?.id}
      })
    },
    downloadFile() {
      this.$store.dispatch('downloadFile', this.project?.project?.id).then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'result.pdf');
        document.body.appendChild(link);
        link.click();
      })
    },
  }
};
</script>

<style>
.map {
  height: 300px;
  border-radius: 30px;
}

.card-info {
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 20px 0;
}

.icon-area {
  width: 44px;
  height: 42px;
  background-color: #c7c6c6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

}

.icon-area img {
  width: 20px;
  height: 20px;
}

button {
  max-width: 75px;
  padding: 14px;
  border: 0;
}
</style>
