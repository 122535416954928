<template>
<div>
  <h3>{{$t('dont_have_permission')}}</h3>
</div>
</template>

<script>
export default {
  name: "UnAuthComponent"
}
</script>

<style scoped>

</style>