<template>
    <div class="container">

        <div class="row align-items-center" v-if="getContentReturns">
            <div class="col-12 align-self-center">
                <div class=" px-3">
                    <h2 class="mhc_slide_title pt-0 " :style='{
                            "color": getContentReturns?.titleColor ? getContentReturns?.titleColor : "#422a47",
                            "font-weight": "bold",
                            "font-size": "30px",

                        }'>{{ resolveByLocal(getContentReturns, 'title') }}
                    </h2>

                    <div class="mhc_slide_content " :style='{
                        "color": getContentReturns?.contentColor ? getContentReturns?.contentColor : "#422a47",
                    }' v-html="resolveByLocal(getContentReturns, 'text')">
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";


export default {
    data() {
        return {
        }
    },
    async created() {
        this.$store.dispatch('getContent')
    },
    methods: {

    },
    computed: {
        ...mapState(['content']),
        getContentReturns() {
            return this.content.find(item => item.name == "returns");
        },
    },
}
</script>
  
<style lang="scss" scoped></style>