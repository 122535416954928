<template>
  <div>
    <div class="form-group form-group mt-3 d-flex justify-content-center align-items-center">
      <div>
        <label>{{ $t('pay_cache') }}</label>
        <select v-model="form.payment_method" class="form-control">
          <option value="cash">{{$t('cash')}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentStep",
  data() {
    return {
      form: {
        payment_method: "cash",
      },
    };
  },
  methods: {
    nextStep() {
      this.$store.dispatch('updateProject', this.form).then(() => {
        this.$store.dispatch('saveProject').then(() => {

        })
      })
      return true
    },
  },
};
</script>

<style>
.form-control {
  line-height: unset !important;
}
</style>
