<template>
  <div class="row container">
    <div class="col-lg-6 col-md-12 mx-auto">
      <article id="post-10004" class="post-10004 page type-page status-publish hentry">
        <h1 class="main_title">{{ $t('contact') }}</h1>
        <div class="entry-content clearfix">
          <div class="gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper" id="gform_wrapper_36">
            <div class="gform_body gform-body">
              <div class="form-group mb-3">
                <label>{{ $t('name') }}</label>
                <div>
                  <input class="form-control" type="text" v-model="form.name">
                  <div v-if="v$.form?.name?.$errors">
                    <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
                      <div class="error-msg">*{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label>{{ $t('email') }}</label>
                <div>
                  <input class="form-control" type="email" v-model="form.fromName">
                  <div v-if="v$.form?.fromName?.$errors">
                    <div class="input-errors" v-for="(error, index) of v$.form.fromName.$errors" :key="index">
                      <div class="error-msg">*{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label>{{ $t('subject') }}</label>
                <div>
                  <input class="form-control" type="text" v-model="form.subject">
                  <div v-if="v$.form?.subject?.$errors">
                    <div class="input-errors" v-for="(error, index) of v$.form.subject.$errors" :key="index">
                      <div class="error-msg">*{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label>{{ $t('message') }}</label>
                <div>
                  <textarea class="form-control" v-model="form.message"></textarea>
                  <div v-if="v$.form?.name?.$errors">
                    <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
                      <div class="error-msg">*{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="mo-btn mo-vtn-block " @click="send">{{$t('send')}}</button>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core/dist/index.esm";
import {email, required, minLength,maxLength} from "@vuelidate/validators";

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      form: {
        name: "",
        fromName: "",
        subject: "",
        message: "",
      },
    }
  },
  validations() {
    return {
      form: {
        name: {required},
        fromName: {required, email},
        subject: [required, minLength(10), maxLength(99)],
        message: [required, minLength(10), maxLength(99)],
      }
    };
  },
  methods:{
    send(){
      this.v$.$validate();
      if (this.v$.$error) {
        return
      }
      this.$store.dispatch('sendContactForm', this.form).then(() => {
        this.$toast.success(this.$t('sent_success'), {
          position: 'top',
          duration: 7000
        });
        this.$router.push({
          name: 'home',
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
