<script>
export default {
  name: "LocalResolver",
  methods: {
    resolveByLocal: function (element, prop) {
      if (!element) return  '';
      if (this.$local == 'ar') {
        return element[`${prop}_ar`];
      }
      return element[`${prop}_en`];
    }
  }
}
</script>